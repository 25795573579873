<template>
  <v-row>
    <v-col
      v-for="(field, index) of fields"
      :cols="field.colSize || 12"
      :key="index"
    >
      <input-v
        v-model="value[field.key]"
        :label="field"
        :opts="opts"
        :editable="!sending && !field.disabled"
        @blur="field.blur  ? field.blur : () => {}"
        @input="field.input"
        @update:valid="validMap.set(field.key, $event)"
      ></input-v>
    </v-col>

    <permissions-field
      v-model="value.permissoes"
      :id="value.empresaId"
      :multiple="showPermissionPerClient"
      :opts="opts"
      :sending.sync="sending"
      :userType="userType"
      @update:valid="validMap.set('permissions', $event)"
    ></permissions-field>

    <action-buttons
      :deleteUser="!isNewUser"
      :resetPassword="!isNewUser"
      :sending="sending"
      :userEmail="user.email"
      :userId="user.id"
      :userName="user.nome"
      @error="$emit('error', $event)"
      @save="doSave()"
      @update:sending="$emit('sending', $event)"
    ></action-buttons>
  </v-row>
</template>

<script>
import { cloneDeep } from 'lodash';
import { AnyAdmin, UserTypeEnum } from '@/core/enums/user-types';

export default {
  components: {
    "action-buttons": () => import("./action.buttons.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    "permissions-field": () => import("./permissionsField"),
  },
  computed: {
    fields: function () {
      const clientes = this.value.corporacao_id
        ? this.opts.clientes.filter((cliente) => cliente.corporacao_id === this.value.corporacao_id)
        : this.opts.clientes;
      const hidePermissionField = !(Array.isArray(this.value.empresaId) && this.value.empresaId.length > 1);
      const isAdminVision = AnyAdmin.includes(this.$store.state.user.tipo_usuario);
      const thirdRowSize = 4 + ((hidePermissionField && !isAdminVision) ? 1 : 0);
      const allFields = [
        {
          key: "email",
          name: "Email",
          colSize: 6,
          input: this.autofillUser,
          type: this.$fieldTypes.TEXT,
          placeholder: 'nome@fornecedor.com.br',
          rules: [{ rule: "required" }, { rule: "email" }],
        },
        {
          key: "usuario",
          name: "Usuário",
          colSize: 6,
          type: this.$fieldTypes.TEXT,
          placeholder: 'Será igual ao e-mail por padrão',
          rules: [{ rule: "required" }],
          input: () => this.fillUser = false,
          blur: () => {
            if (!this.value.usuario) {
              this.fillUser = true;
              this.value.usuario = this.value.email;
            }
          },
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          placeholder: 'Nome e sobrenome',
          rules: [{ rule: "required" }, { rule: "min", params: { size: 3 }}],
        },
        {
          key: "corporacao_id",
          name: "Grupo de Empresas",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "gruposDeEmpresa", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
          noClearable: true,
          input: () => this.value.empresaId = null,
          colSize: thirdRowSize,
        },
        {
          key: "empresaId",
          name: this.value.corporacao_id ? "Empresas" : "Empresa",
          type: this.value.corporacao_id ? this.$fieldTypes.AUTOCOMPLETE_MULTIPLE : this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: clientes, key: "id", name: "fantasia" },
          rules: [{ rule: "required" }],
          colSize: thirdRowSize,
        },
        {
          hide: hidePermissionField,
          key: "tipo_permissionamento",
          name: "Permissões iguais para todas empresas?",
          type: this.$fieldTypes.SWITCH,
          defaultValue: false,
          colSize: 4,
          rel: {
            key: "id",
            name: "nome",
            toEdit: [
              { id: true, nome: "Sim" },
              { id: false, nome: "Não" },
            ],
          },
        },
        {
          hide: !this.isNewUser,
          key: "senha",
          name: "Senha Provisória",
          colSize: 8 + (!isAdminVision ? 2 : 0),
          type: this.$fieldTypes.TEXT,
          placeholder: 'Novas contas precisam de uma senha inicial',
          rules: [{ rule: "required" }],
        },
        {
          hide: !isAdminVision,
          key: "sso_only",
          name: "Login Exclusivo SSO",
          defaultValue: false,
          colSize: 2,
          type: this.$fieldTypes.SWITCH,
          rel: {
            toEdit: [
              { id: true, nome: "Sim" },
              { id: false, nome: "Não" },
            ],
            key: "id",
            name: "nome",
          },
        },
        {
          key: "ativo",
          name: "Status",
          colSize: 2,
          type: this.$fieldTypes.SWITCH,
          rel: {
            toEdit: [
              { id: true, nome: "Ativo" },
              { id: false, nome: "Inativo" },
            ],
            key: "id",
            name: "nome",
          },
        },
      ];

      return allFields.filter(({ hide }) => !hide);
    },
    showPermissionPerClient: function () {
      const { empresaId, tipo_permissionamento } = this.value;
      return Array.isArray(empresaId) && empresaId.length > 1 && !tipo_permissionamento;
    },
    userType: function () {
      return UserTypeEnum.GERENCIAL_CLIENTE;
    },
  },
  created: function () {
    this.updateValue(this.user);
  },
  data: function () {
    const validMap = new Map();    
    return {
      fillUser: true,
      permissoesList: {},
      validMap,
      sending: false,
      value: {
        ativo: true,
        corporacao_id: 0,
        email: null,
        empresaId: null,
        nome: null,
        permissoes: [],
        senha: null,
        tipo_permissionamento: false,
        usuario: null,
        sso_only: false,
      },
    };
  },
  methods: {
    autofillUser: function (nextValue) {
      if (this.fillUser) {
        this.value.usuario = nextValue;
      }
    },
    changePermissionModel: function () {
      let { empresaId, permissoes } = this.value;
      const isStringArray = Array.isArray(permissoes) && permissoes.length > 0 && typeof permissoes[0] === 'string';

      if (this.showPermissionPerClient && isStringArray) {
        permissoes = empresaId.map((empresa_id) => ({
          empresa_id,
          permissoes: isStringArray ? cloneDeep(permissoes) : [],
        }));
      } else if (!this.showPermissionPerClient && !isStringArray) {
        const id = (Array.isArray(empresaId) && empresaId.length > 0) ? empresaId[0] : isFinite(empresaId) ? empresaId : null;
        permissoes = isStringArray ? permissoes : permissoes.find(({ empresa_id }) => empresa_id === id)?.permissoes || [];
        permissoes = cloneDeep(permissoes);
      }

      this.value.permissoes = permissoes;
    },
    doSave: function () {
      const fieldErros = [];
      this.validMap.forEach((validation, key) => {
        if (!validation()) {
          fieldErros.push(key);
        }
      });

      if (fieldErros.length > 0) {
        const fieldNames = fieldErros
          .map((fieldKey) => {
            const found = this.fields.find(({ key }) => key === fieldKey);

            if (found) {
              return found.name;
            }

            return fieldKey === 'permissions' ? 'Permissões' : fieldKey;
          })
          .join(', ');
        return this.$emit('error', { error: `Campos inválidos: ${fieldNames}.` });
      }

      const { id } = this.user;
      const { empresaId, permissoes, ...values } = this.value;
      const empresaIds = Array.isArray(empresaId) ? empresaId : [empresaId];
      let parsedPermissoes = [];

      if (this.showPermissionPerClient) {
        parsedPermissoes = permissoes.filter(({ empresa_id }) => empresaIds.includes(empresa_id));
      } else {
        parsedPermissoes = empresaIds.map((empresa_id) => ({ empresa_id, permissoes }));
      }

      const body = {
        ...values,
        tipo_usuario: UserTypeEnum.GERENCIAL_CLIENTE,
        permissoes: parsedPermissoes,
      };

      if (id && isFinite(id)) {
        body.id = id;
      }

      if (!body.corporacao_id) {
        delete body.corporacao_id;
      }

      if (!body.senha) {
        delete body.senha;
      }

      this.$emit('save', body);
    },
    parseUserPermissoes: function ({ empresa_id, permissoes }) {
      const perms = Object.entries(permissoes || {}).reduce(
        (accA, [module, resources]) => {
          const keys = Object.entries(resources || {}).reduce(
            (accB, [resource, actions]) => {
              const keys = Object.keys(actions || {}).map(
                (action) => `${module}.${resource}.${action}`
              );
              return [...accB, ...keys];
            },
            []
          );
          return [...accA, ...keys];
        },
        []
      );
      return { empresa_id, permissoes: perms };
    },
    updateValue: function (nextValue = {}) {
      const parsedEmpresa = Array.isArray(nextValue.empresas) && nextValue.empresas.length > 0
        ? nextValue.empresas.map((empresa) => this.parseUserPermissoes(empresa))
        : [];
      const empresaIds = parsedEmpresa.map(({ empresa_id }) => empresa_id);
      const empresaId = nextValue.corporacao_id ? empresaIds : empresaIds.length > 0 ? empresaIds[0] : null;
      const permissoes = (parsedEmpresa.length > 1 && !nextValue.tipo_permissionamento) ?
        parsedEmpresa :
        (parsedEmpresa.length === 1 || nextValue.tipo_permissionamento) ?
          parsedEmpresa[0].permissoes :
          [];
      this.value = {
        ativo: nextValue.ativo,
        corporacao_id: nextValue.corporacao_id || 0,
        email: nextValue.email,
        empresaId,
        nome: nextValue.nome,
        permissoes,
        senha: nextValue.senha,
        tipo_permissionamento: nextValue.tipo_permissionamento,
        usuario: nextValue.usuario,
        sso_only: nextValue.sso_only,
      };
    },
  },
  props: {
    isNewUser: { type: Boolean, default: false },
    opts: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
  },
  watch: {
    showPermissionPerClient: function () {
      this.changePermissionModel();
    },
    user: {
      deep: true,
      handler: function (nextValue) {
        this.updateValue(nextValue);
      },
    },
  },
}
</script>
